/** 系统枚举 */
export const APP_TYPE_ENUM = {
  CENTER: {
    value: 'center',
    label: '总控'
  },
  LOGISTICS: {
    value: 'logistics',
    label: '集配中心'
  },
  GENERAL: {
    value: 'general',
    label: '业务中心'
  },
  DELIVERY: {
    value: 'delivery',
    label: '自提点'
  },
  TDC: {
    value: 'tdc',
    label: '城市仓'
  }
}

/** 物流车队枚举 */
export const VEHICLE_MODEL = [{
  value: '4.2米以下',
  label: '4.2米以下'
}, {
  value: '4.2米',
  label: '4.2米'
},{
  value: '5.2米',
  label: '5.2米'
},{
  value: '6.8米',
  label: '6.8米'
},{
  value: '7.7米',
  label: '7.7米'
},{
  value: '9.6米',
  label: '9.6米'
},{
  value: '13米及以上',
  label: '13米及以上'
}]
