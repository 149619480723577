<template>
  <div class="management bj">
    <div class="title">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="Refresh"
      >
      </el-button>
      <el-button type="primary" @click="handelAdd">添加</el-button>
      <div class="seach">
        <div class="inputs">
          <el-input v-model="from.name" placeholder="请输入角色名称" clearable>
          </el-input>
        </div>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >查询</el-button
        >
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column
          prop="fullname"
          align="center"
          label="序号"
          width="100px"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.id) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="fullname"
          align="center"
          label="角色名称"
          minWidth="350px"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.name) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" minWidth="150px">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handelSavePower(scope.$index, scope.row)"
              >操作权限
            </el-button>
            <el-button
              type="text"
              v-if="isShowDataPower"
              @click="onHandelDataPower(scope.row)"
              >数据权限
            </el-button>
            <el-button
              type="text"
              @click="handleUpdate(scope.$index, scope.row)"
              >修改
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--  添加、编辑角色弹框  -->
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      v-if="centerDialogVisible"
      width="40%"
      class="dialog"
      center
    >
      <div class="contents">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="角色名称" prop="name">
            <el-input
              v-model="ruleForm.name"
              placeholder="请输入角色名称"
              style="width: 302px"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom">确认</el-button>
      </span>
    </el-dialog>

    <!--  编辑权限弹框 -->
    <el-dialog
      title="编辑权限"
      :visible.sync="centerDialogVisiblePower"
      v-if="centerDialogVisiblePower"
      width="40%"
      class="dialog"
      center
    >
      <div class="contents">
        <el-tree
          :data="powerListData"
          show-checkbox
          ref="trees"
          node-key="id"
          @check="handleCheckChange111"
          @check-change="handleCheckChange"
          :default-checked-keys="checkKeys"
          :props="defaultProps"
          @current-change="currentchange"
        >
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisiblePower = false">取 消</el-button>
        <el-button type="primary" @click="saveRolePower">确认</el-button>
      </span>
    </el-dialog>

    <!-- 数据权限 start -->
    <el-dialog
      title="数据权限"
      :visible.sync="dialogVisiblePower"
      @close="onHandleClose"
      width="40%"
      class="dialog"
      center
    >
      <div class="content">
        <div class="label">集配中心：</div>
        <el-select placeholder="请选择集配中心" v-model="logisticsId" clearable>
          <el-option
            v-for="item of logisticsList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <span slot="footer">
        <el-button @click="onHandleClose">取 消</el-button>
        <el-button type="primary" @click="onHandleSubmit">确 认</el-button>
      </span>
    </el-dialog>
    <!-- 数据权限 end -->

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="from.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { APP_TYPE_ENUM } from "@/utils/enum/app";

export default {
  name: "rolement",
  components: {},
  data() {
    return {
      loading: false,
      from: {
        name: "",
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      total: 0,
      title: "",
      showId: false,
      centerDialogVisible: false,
      ruleForm: {
        name: "",
        id: 0,
      },
      rules: {
        name: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
      },
      centerDialogVisiblePower: false,
      powerListData: [],
      checkPowerList: [],
      checkPowerIds: [],
      checkKeys: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      roleId: 0,
      manageIsdentity: "",
      dialogVisiblePower: false,
      dataRoleId: "",
      logisticsId: "",
      // 集配中心列
      logisticsList: [],
      APP_TYPE_ENUM,
    };
  },
  computed: {
    /**
     * 是否展示数据权限
     */
    isShowDataPower() {
      const storage = JSON.parse(sessionStorage.getItem("userInfo"));
      let type = storage?.business_type_code;
      let res = APP_TYPE_ENUM.CENTER.value === type;
      // console.log("🆒 isShowDataPower", type, res, storage);
      return res;
    },
  },
  created() {
    this.manageIsdentity = sessionStorage.getItem("manageIsdentity");
    this.getRoleList();
  },
  mounted() {
    if (this.isShowDataPower) {
      this.getAjaxLogisticsList();
    }
  },
  methods: {
    // 获取列表数据
    getRoleList() {
      this.loading = true;
      // 总控中心
      if (this.manageIsdentity == "general") {
        this.$api.general.roleGeneralList(this.from).then((res) => {
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        });
      } // 集配中心
      else if (this.manageIsdentity == "logistics") {
        this.$api.logistics.getRoleList(this.from).then((res) => {
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        });
      } // 自提点中心
      else if (this.manageIsdentity == "delivery") {
        this.$api.delivery.getRoleList(this.from).then((res) => {
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        });
      }
      // 城市仓
      else if (this.manageIsdentity == "tdc") {
        this.$api.tdc.getRoleList(this.from).then((res) => {
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        });
      }
    },
    /**
     * 编辑数据权限
     */
    onHandelDataPower(row) {
      this.dialogVisiblePower = true;
      this.dataRoleId = row?.id;
      this.logisticsId = row?.logistic_business_ids?.[0] || "";
      // console.log("🆒 onHandelDataPower", row);
    },
    /**
     * 隐藏数据权限弹窗
     */
    onHandleClose() {
      this.dialogVisiblePower = false;
      this.dataRoleId = "";
      this.logisticsId = "";
    },
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.ajaxGetLogisticsForRole();
        let option = {
          id: "",
          name: "全部",
        };
        data.unshift(option);
        this.logisticsList = data;
        // console.log("🆒 ajax getAjaxLogisticsList", data, this.logisticsList);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    /**
     * 保存数据权限
     */
    async onHandleSubmit() {
      try {
        const params = {
          roleId: this.dataRoleId,
          logisticBusinessIds: (this.logisticsId && [this.logisticsId]) || [],
        };
        await this.$api.general.ajaxDataRoleUpdate(params);
        this.$message({ message: "修改成功!", type: "success" });
        this.getRoleList();
        this.dialogVisiblePower = false;
        console.log("ajax getAjaxLogisticsList", data);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    // 表单搜索
    search() {
      this.getRoleList();
    },
    // 刷新
    Refresh() {
      this.from = {
        name: "",
        page: 1,
        pageSize: 10,
      };
      this.getRoleList();
    },
    // 分页选择显示数量
    handleSizeChange(val) {
      this.from.page = 1;
      this.from.pageSize = val;
      this.getRoleList();
    },
    // 分页选择当前页
    handleCurrentChange(val) {
      this.from.page = val;
      this.getRoleList();
    },
    // 添加角色
    handelAdd() {
      this.ruleForm = {
        name: "",
        id: 0,
      };
      this.showId = true;
      this.title = "添加角色";
      this.centerDialogVisible = true;
    },
    // 添加、编辑确定
    submitFrom() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.showId) {
            this.insert();
          } else {
            this.update();
          }
        } else {
          return false;
        }
      });
    },
    insert() {
      // 总控中心
      if (this.manageIsdentity == "general") {
        this.$api.general.addRoleGeneral(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({ message: "添加角色成功!", type: "success" });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        });
      } // 集配中心
      else if (this.manageIsdentity == "logistics") {
        this.$api.logistics.saveRole(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({ message: "添加角色成功!", type: "success" });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        });
      } // 自提点中心
      else if (this.manageIsdentity == "delivery") {
        this.$api.delivery.saveRole(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({ message: "添加角色成功!", type: "success" });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        });
      }
      // 城市仓
      else if (this.manageIsdentity == "tdc") {
        this.$api.tdc.saveRole(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({ message: "添加角色成功!", type: "success" });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        });
      }
    },
    // 修改角色
    handleUpdate(index, row) {
      this.ruleForm = {
        name: row.name,
        id: row.id,
      };
      this.title = "编辑角色";
      this.centerDialogVisible = true;
    },
    update() {
      // 总控中心
      if (this.manageIsdentity == "general") {
        this.$api.general.updateRoleGeneral(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({ message: "编辑角色成功！", type: "success" });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        });
      } // 集配中心
      else if (this.manageIsdentity == "logistics") {
        this.$api.logistics.saveRole(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({ message: "编辑角色成功!", type: "success" });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        });
      } // 自提点中心
      else if (this.manageIsdentity == "delivery") {
        this.$api.delivery.saveRole(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({ message: "编辑角色成功!", type: "success" });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        });
      }
      // 城市仓
      else if (this.manageIsdentity == "tdc") {
        this.$api.tdc.saveRole(this.ruleForm).then((res) => {
          if (res.code == 20000) {
            this.$message({ message: "编辑角色成功!", type: "success" });
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.Refresh();
            }, 500);
          }
        });
      }
    },
    // 编辑角色权限
    handelSavePower(index, row) {
      this.roleId = row.id;
      this.centerDialogVisiblePower = true;
      // 总控中心
      if (this.manageIsdentity == "general") {
        this.$api.general.getAllMenu({ roleId: row.id }).then((res) => {
          this.powerListData = res.data.menu;
          this.checkKeys = res.data.selectedIds;
          this.checkPowerIds = this.checkKeys.join(",");
          console.log(this.checkKeys);
        });
      } // 集配中心
      else if (this.manageIsdentity == "logistics") {
        this.$api.logistics.getAllMenu({ roleId: row.id }).then((res) => {
          this.powerListData = res.data.menu;
          this.checkKeys = res.data.selectedIds;
          this.checkPowerIds = this.checkKeys.join(",");
        });
      } // 自提点中心
      else if (this.manageIsdentity == "delivery") {
        this.$api.delivery.getAllMenu({ roleId: row.id }).then((res) => {
          this.powerListData = res.data.menu;
          this.checkKeys = res.data.selectedIds;
          this.checkPowerIds = this.checkKeys.join(",");
        });
      }
      // 城市仓
      else if (this.manageIsdentity == "tdc") {
        this.$api.tdc.getAllMenu({ roleId: row.id }).then((res) => {
          this.powerListData = res.data.menu;
          this.checkKeys = res.data.selectedIds;
          this.checkPowerIds = this.checkKeys.join(",");
        });
      }
    },
    handleCheckChange(e, el, els) {
      this.checkPowerList = this.$refs.trees.getCheckedNodes(false, true);
      let checkMenus = [];
      this.checkPowerList.forEach((item) => {
        checkMenus.push(item.id);
      });
      this.checkPowerIds = checkMenus.join(",");
      console.log(this.checkPowerIds);
    },
    handleCheckChange111(data, checked) {},
    currentchange(e) {},
    // 编辑权限
    saveRolePower() {
      console.log(this.checkPowerIds);
      // 集配中心
      if (this.manageIsdentity == "general") {
        this.$api.general
          .updateRoleMenuIds({
            roleId: this.roleId,
            menuIds: this.checkPowerIds,
          })
          .then((res) => {
            if (res.code == 20000) {
              this.centerDialogVisiblePower = false;
              this.$message({ message: "编辑角色权限成功！", type: "success" });
              setTimeout(() => {
                this.Refresh();
              }, 500);
            }
          });
      } // 集配中心
      else if (this.manageIsdentity == "logistics") {
        this.$api.logistics
          .saveRoleMenuIds({ roleId: this.roleId, menuIds: this.checkPowerIds })
          .then((res) => {
            if (res.code == 20000) {
              this.centerDialogVisiblePower = false;
              this.$message({ message: "编辑角色权限成功！", type: "success" });
              setTimeout(() => {
                this.Refresh();
              }, 500);
            }
          });
      } // 自提点中心
      else if (this.manageIsdentity == "delivery") {
        this.$api.delivery
          .saveRoleMenuIds({ roleId: this.roleId, menuIds: this.checkPowerIds })
          .then((res) => {
            if (res.code == 20000) {
              this.centerDialogVisiblePower = false;
              this.$message({ message: "编辑角色权限成功！", type: "success" });
              setTimeout(() => {
                this.Refresh();
              }, 500);
            }
          });
      }
      // 城市仓
      else if (this.manageIsdentity == "tdc") {
        this.$api.tdc
          .saveRoleMenuIds({ roleId: this.roleId, menuIds: this.checkPowerIds })
          .then((res) => {
            if (res.code == 20000) {
              this.centerDialogVisiblePower = false;
              this.$message({ message: "编辑角色权限成功！", type: "success" });
              setTimeout(() => {
                this.Refresh();
              }, 500);
            }
          });
      }
    },
  },
};
</script>
<style lang="scss">
.management {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    .titlname {
      font-size: 17px;
      color: #349ee6;
      margin-bottom: 25px;
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 100px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }

  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}

.colors {
  color: #fa884c;
}

.content {
  display: flex;
  justify-content: center;
  padding: 100px 0;
  .label {
    line-height: 40px;
  }
  .el-select {
    width: 300px;
  }
}
</style>
